<template>
  <footer id="footer">
    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-xl-4">
                    <div class="footer-l">
                        <div class="footer-logo">
                            <a href="https://timerni.com">
                                <img src="https://timerni.com/assets/frontend/img/logo/footer-logo.png" alt="logo">
                            </a>
                        </div>
                        <div class="fo-con">
                            <p class="text-justify">
                                We are specialised in customised Software Development, Research, and Data Engineering. We will build your data pipelines and transform your system to be ready for the next 20-30 years. We have a dedicated team to serve your requirements. Please feel free to follow our social sites if you need help with project creation or delivery.
                            </p>
                        </div>
                        <div class="f-s-icon">
                            <a href="https://www.facebook.com/timerniglobal" target="_blank" class="so-f">
                                <img src="https://timerni.com/assets/frontend/img/icons/facebook.png" alt="facebook">
                            </a>
                            <a href="javascript:void(0);" class="so-t">
                                <img src="https://timerni.com/assets/frontend//img/icons/twitter.png" alt="twitter">
                            </a>
                            <a href="https://www.linkedin.com/company/timerni/mycompany/" target="_blank" class="so-i">
                                <img src="https://timerni.com/assets/frontend/img/icons/linkedin.png" alt="linkedin">
                            </a>
                            <a href="https://www.instagram.com/timerniglobal/" target="_blank" class="so-l">
                                <img src="https://timerni.com/assets/frontend/img/icons/instragram.png" alt="instragram">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-xl-8">
                    <div class="f-link">
                        <div class="f-link-h">
                            <h4 class="s-sub-heading">
                                <span class="s-sub-c">Pages </span>
                            </h4>
                            <ul class="mt-60">
                                <li>
                                    <a href="https://timerni.com">Home</a>
                                </li>
                                <li>
                                    <a href="https://timerni.com/about">About</a>
                                </li>
                                <li>
                                    <a href="https://timerni.com/services">Services</a>
                                </li>
                                <li>
                                    <a href="https://timerni.com/project">Projects</a>
                                </li>
                                <li>
                                    <a href="https://timerni.com/contact">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                        <div class="f-link-h">
                            <h4 class="s-sub-heading">
                                <span class="s-sub-c">Useful Links </span>
                            </h4>
                            <ul class="mt-60">
                                <li>
                                    <a href="https://timerni.com/setting/privacy-policy">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="https://timerni.com/setting/terms-condition">Terms & Conditions</a>
                                </li>
                                <li>
                                    <a href="https://timerni.com/setting/return-refund-policy">Return & Refund Policy</a>
                                </li>
                                <li>
                                    <a href="https://timerni.com/career">Career</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Testimonials</a>
                                </li>
                            </ul>
                        </div>
                        <div class="f-link-h">
                            <h4 class="s-sub-heading">
                                <span class="s-sub-c">Contact Info.</span>
                            </h4>
                            <div class="mt-60">
                                <div class="contact-info-f grid-2">
                                    <div>
                                        <div class="f-icons-f">
                                            <img src="https://timerni.com/assets/frontend/img/icons/location.png" alt="location">
                                            <p>
                                                <span>
                                                Bangladesh <br>
                                                East Rampura,Khilgaon<br>
                                                Dhaka,1219</span>
                                            </p>
                                        </div>
                                        <div class="f-icons-f">
                                            <img src="https://timerni.com/assets/frontend/img/icons/phone-line.png" alt="phone-line">
                                            <p>
                                                <a href="tel:+88 (0) 174 396 6200">+88 (0) 174 396 6200</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="f-icons-f">
                                            <img src="https://timerni.com/assets/frontend/img/icons/location.png" alt="location">
                                            <p>
                                                <span>
                                            United Kingdom<br>
                                            189 Foundry Lane<br>
                                            Southampton SO153JZ</span>
                                            </p>
                                        </div>
                                        <div class="f-icons-f pb-1">
                                            <img src="https://timerni.com/assets/frontend/img/icons/phone-line.png" alt="phone-line">
                                            <p>
                                                <a href="tel:+44 (0) 238 052 8262">+44 (0) 238 052 8262</a><br>
                                            </p>
                                        </div>
                                        <div class="f-icons-f">
                                            <img src="https://timerni.com/assets/frontend/img/icons/phone-line.png" alt="phone-line">
                                            <p>
                                                <a href="tel:+44 (0) 755 482 3078">+44 (0) 755 482 3078</a><br>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="subscribes-f">
                                <h4 class="s-sub-heading text-start pt-2 pb-3">
                                    <span class="s-sub-c">Subscribe Us</span>
                                </h4>
                                <form action="https://timerni.com/save/subscribe" method="POST" class="subs-d">
                                    <input type="hidden" name="_token" value="XrWC9ODZD9DdggT01oy2j5Ep1IXK11SMtbODMCmc">                                    <input name="email" class="form-control" type="email" autocorrect="off" autocapitalize="off" placeholder="Enter Your Email">
                                    <button type="submit" class="cmn-btn-blue text-white btn-green">Subscribe</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright text-center">
                <p>Copyright 2022 <i class="fas fa-copyright"></i> <a href="javascript:void(0);">Time research & innovation</a> All Rights Reserved
                </p>
            </div>
        </div>
        <div class="f-bottom-img">
            <img src="https://timerni.com/assets/frontend/img/footer-bg.png" alt="footer" loading="lazy">
        </div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>